import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth.module";
import API from "@/common/api/api.functions";
// eslint-disable-next-line no-unused-vars
import ApiService from "@/common/api.service";
import i18n from "@/i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    scrollNow: null,
    footerBoundingSize: null,
    language: null,
    loading: 0,
    htmlOverflowHidden: false,
    restorePass: false,
    privateInformationShowed: !!localStorage.getItem("privateInformation"),
    headerImage: null,
    headerTitle: null,
    tabTitleBase: "REG.bmstu",
    tabTitle: null,
    eventNames: [],
    eventArticleTypes: [],
    requestStatuses: [],
    requestStatusIcons: [
      {
        id: 1,
        icon: "approved-double"
      },
      {
        id: 3,
        icon: "question"
      },
      {
        id: 0,
        icon: "cancelled"
      },
      {
        id: 15,
        icon: "article-check"
      },
      {
        id: 14,
        icon: "upload"
      },
      {
        id: 13,
        icon: "article-check"
      },
      {
        id: 4,
        icon: "upload"
      },
      {
        id: 2,
        icon: "article-check"
      },
      {
        id: 5,
        icon: "question"
      },
      {
        id: 6,
        icon: "article-review"
      },
      {
        id: 7,
        icon: "article-review-2"
      },
      {
        id: 8,
        icon: "article-rework"
      },
      {
        id: 9,
        icon: "article-approved-rework"
      },
      {
        id: 10,
        icon: "article-approved-rework-2"
      },
      {
        id: 11,
        icon: "approved"
      },
      {
        id: 12,
        icon: "cancelled"
      }
    ],
    requestStatusCategories: [],
    requestOperationTypes: [],
    defaultFields: [],
    defaultFieldsIds: [],
    notFound: false,
    underDev: false,
    hideAll: false,
    ws: null,
    wsLastData: null,
    validatorMessages: {
      alpha: "Поле может содержать только буквы",
      alpha_dash: "Поле может содержать только буквы, цифры и дефис",
      alpha_num: "Поле может содержать только буквы и цифры",
      alpha_spaces: "Поле может содержать только буквы и пробелы",
      between: "Поле должно быть между {min} и {max}",
      confirmed: "Поле не совпадает с {target}",
      digits: "Поле должно быть числовым и длиной {length}",
      dimensions: "Поле должно быть {width} пикселей на {height} пикселей",
      email: "Поле должно быть действительным электронным адресом",
      excluded: "Поле должно быть допустимым значением",
      ext: "Поле должно быть действительным файлом.",
      image: "Поле должно быть изображением",
      oneOf: "Поле должно быть допустимым значением",
      integer: "Поле должно быть целым числом",
      length: "Длина поля должна быть {length}",
      max: "Поле не может быть более {length} символов",
      max_value: "Поле должно быть {max} или менее",
      mimes: "Поле должно иметь допустимый тип файла.",
      min: "Поле должно быть не менее {length} символов",
      min_value: "Поле должно быть {min} или больше",
      numeric: "Поле должно быть числом",
      regex: "Поле имеет ошибочный формат",
      required: "Поле обязательно для заполнения",
      required_if: "Поле обязательно для заполнения",
      size: "Поле должно быть меньше, чем {size}KB"
    },
    unreadMessages: 0
  },
  mutations: {
    UPDATE_SCROLL_NOW(state, value) {
      state.scrollNow = value;
    },
    UPDATE_FOOTER_BOUNDING_SIZE(state, value) {
      state.footerBoundingSize = value;
    },
    // eslint-disable-next-line no-unused-vars
    UPDATE_LANGUAGE(state, value) {
      state.language = value;
      localStorage.setItem("language", value);
      if (i18n) {
        i18n.locale = value;
      }
      const newMessages = {};
      ApiService.setLangHeader(value);
      for (const key of Object.keys(state.validatorMessages)) {
        newMessages[key] = i18n.t(`validatorMessages.${key}`);
      }
      this.state.validatorMessages = newMessages;
    },
    // eslint-disable-next-line no-unused-vars
    async UPDATE_LANGUAGE_RELOAD(state, value) {
      state.loading = true;
      // window.indexedDB.deleteDatabase("reg-cache");
      state.language = value;
      // let user = localStorage.getItem("user");
      // let token = localStorage.getItem("token");
      // let privateInformation = localStorage.getItem("privateInformation");
      // localStorage.clear();
      // if (user) localStorage.setItem("user", user);
      // if (token) localStorage.setItem("token", token);
      // if (privateInformation)
      //   localStorage.setItem("privateInformation", privateInformation);
      localStorage.setItem("language", value);
      if (state.auth.user) {
        let user = await API.patchUser({ language: value });
        if (user) localStorage.setItem("user", JSON.stringify(user));
      }
      const request = window.indexedDB.open("reg-cache");
      request.onsuccess = function(event) {
        let db = event.target.result;
        let store = db
          .transaction(["keyvaluepairs"], "readwrite")
          .objectStore("keyvaluepairs");
        store.clear();
        window.location.reload();
      };
    },
    UPDATE_LOADING(state, value) {
      state.loading = value >= 0 ? value : 0;
    },
    UPDATE_HEADER_IMAGE(state, value) {
      state.headerImage = value;
    },
    UPDATE_HEADER_TITLE(state, value) {
      state.headerTitle = i18n.t(value);
    },
    UPDATE_OVERFLOW(state, value) {
      state.htmlOverflowHidden = value;
    },
    UPDATE_RESTORE_PASS(state, value) {
      state.restorePass = value;
    },
    UPDATE_EVENT_NAMES(state, value) {
      state.eventNames = value.map(val => ({
        label: val.title,
        value: val.id
      }));
    },
    UPDATE_EVENT_ARTICLE_TYPES(state, value) {
      state.eventArticleTypes = value.map(val => ({
        label: val.title,
        value: val.id
      }));
    },
    UPDATE_DEFAULT_FIELDS(state, value) {
      state.defaultFields = value;
      state.defaultFieldsIds = value.map(val => val.id);
    },
    UPDATE_NOT_FOUND(state, value) {
      state.notFound = value;
    },
    UPDATE_UNDER_DEV(state, value) {
      state.underDev = value;
    },
    UPDATE_HIDE_ALL(state, value) {
      state.hideAll = value;
    },
    UPDATE_REQUEST_STATUSES(state, value) {
      state.requestStatuses = value;
    },
    UPDATE_REQUEST_OPERATION_TYPES(state, value) {
      state.requestOperationTypes = value;
    },
    UPDATE_TAB_TITLE(state, value) {
      state.tabTitle = i18n.t(value);
      document.title = this.getters.htmlTitle;
    },
    UPDATE_WS(state, value) {
      state.ws = value;
    },
    UPDATE_WS_LAST_DATA(state, value) {
      state.wsLastData = value;
    },
    UPDATE_UNREAD_MESSAGES(state, value) {
      state.unreadMessages = value;
    }
  },
  actions: {
    updateLangFromLocalStorage({ commit }) {
      const user = localStorage.getItem("user");
      const language = user
        ? JSON.parse(user).language
        : localStorage.getItem("language");
      commit("UPDATE_LANGUAGE", language);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    async fetchEventRequestStatuses({ commit }) {
      commit(
        "UPDATE_REQUEST_STATUSES",
        await API.getEventRequestStatuses()
          .then(res => res.data)
          .catch(() => {
            this._vm.$toast.error("Ошибка при получении статусов мероприятий");
            return [];
          })
      );
    },
    async fetchEventRequestOperationTypes({ commit }) {
      commit(
        "UPDATE_REQUEST_OPERATION_TYPES",
        await API.getEventRequestOperationTypes()
          .then(res => res.data)
          .catch(() => {
            this._vm.$toast.error("Ошибка при получении типов операций заявок");
            return [];
          })
      );
    },
    async fetchEventNames({ commit }) {
      commit(
        "UPDATE_EVENT_NAMES",
        await API.getEventNames()
          .then(res => res.data)
          .catch(() => {
            this._vm.$toast.error("Ошибка при получении типов мероприятий");
            return [];
          })
      );
    },
    async fetchEventArticleTypes({ commit }) {
      commit(
        "UPDATE_EVENT_ARTICLE_TYPES",
        await API.getEventArticleTypes()
          .then(res => res.data)
          .catch(() => {
            this._vm.$toast.error("Ошибка при получении типов участия");
            return [];
          })
      );
    },
    async fetchDefaultFields({ commit }) {
      commit(
        "UPDATE_DEFAULT_FIELDS",
        await API.getDefaultFields()
          .then(res => {
            if (!this.getters.language)
              this.commit("UPDATE_LANGUAGE", res.headers["content-language"]);
            return res;
          })
          .then(res => res.data)
          .catch(() => {
            this._vm.$toast.error("Ошибка при получении полей по-умолчанию");
            return [];
          })
      );
    },
    async fieldToFront({ state }, { field, forUser = true }) {
      let nowId = field.id;
      let eventTypeRequired = field.copy_of;
      if (
        !eventTypeRequired &&
        nowId &&
        state.defaultFieldsIds.includes(nowId)
      ) {
        eventTypeRequired = nowId;
        nowId = null;
      }
      let outField = {
        required: !!field.required,
        participationTypes: field.participation_types || [],
        eventTypes: field.event_types || [],
        col: field.width,
        order: field.order,
        userField: field.user_field,
        deleted: field.deleted,
        hidden: false,
        table: field.table
      };
      if (forUser) {
        outField.label = field.title;
        outField.help = field.help;
      } else {
        outField.label_ru = field.title_ru;
        outField.label_en = field.title_en;
        outField.help_ru = field.help_ru;
        outField.help_en = field.help_en;
      }
      if (nowId) outField.id = nowId;
      if (eventTypeRequired) {
        outField.eventTypeRequired = eventTypeRequired;
        if (
          !outField.participationTypes?.length ||
          !outField.eventTypes?.length
        ) {
          let defaultField = this.state.defaultFields.find(
            val => val.id === eventTypeRequired
          );
          if (defaultField) {
            outField.participationTypes = defaultField.participation_types;
            outField.eventTypes = defaultField.event_types;
          }
        }
      }
      if (field.max_length) outField.maxLength = field.max_length;
      switch (field.resourcetype) {
        case "TextCF":
          if (field.multiline) {
            outField.type = "textarea";
          } else outField.type = "text";
          break;
        case "EmailCF":
          outField.type = "email";
          break;
        case "PhoneCF":
          outField.type = "tel";
          break;
        case "LinkCF":
          outField.type = "url";
          break;
        case "IntegerCF":
          outField.type = "number";
          if (field.min) outField.min = field.min;
          if (field.max) outField.max = field.max;
          break;
        case "FloatCF":
          outField.type = "float";
          if (field.min) outField.min = field.min;
          if (field.max) outField.max = field.max;
          break;
        case "DateTimeCF":
          if (field.datetime_type === "DATE") {
            outField.type = "date";
          } else if (field.datetime_type === "TIME") outField.type = "time";
          break;
        case "FileCF":
          outField.type = "file";
          outField.document = !!field.is_event_document;
          if (field.filename_extensions?.length)
            outField.fileTypes = field.filename_extensions;
          if (field.file_volume) outField.maxSize = field.file_volume;
          break;
        case "BooleanCF":
          outField.type = "checkbox";
          if (field.button) outField.button = !!field.button;
          break;
        case "ChoicesCF":
          outField.type = "checkbox_group";
          if (["RADIOBUTTONS", "SELECT"].includes(field.type)) {
            outField.type = "radio_buttons";
            outField.select = field.type === "SELECT";
          }
          if (field.buttons && !outField.select) {
            outField.button = !!field.buttons;
            outField.buttonGroup = !!field.buttons;
          }
          if (field.inline) outField.inline = !!field.inline;
          outField.items = [];
          if (field.choices?.length) {
            outField.items = field.choices.map(val => ({
              ...(forUser
                ? {
                    label: val.title
                  }
                : {
                    label_ru: val.title_ru,
                    label_en: val.title_en
                  }),
              value: val.value ? val.value : val.id,
              id: val.id,
              deleted: !!val.deleted
            }));
          }
          break;
      }
      return outField;
    },
    async fieldToBack(_, field, isDocument = false) {
      let nowId = field.id;
      let outField = {
        required: !!field.required,
        width: field.col,
        order: field.order,
        title: field.label_ru,
        title_ru: field.label_ru,
        title_en: field.label_en,
        help_ru: field.help_ru,
        help_en: field.help_en,
        user_field: field.userField,
        deleted: !!field.deleted,
        hidden: !!field.hidden,
        table: !!field.table
      };
      if (isDocument) outField.is_event_document = true;
      if (nowId) outField.id = nowId;
      if (field.eventTypeRequired) outField.copy_of = field.eventTypeRequired;
      outField.max_length = field.maxLength ? field.maxLength : 0;
      if (!field.type) {
        field.type = "text";
      }
      switch (field.type) {
        case "text":
          outField.resourcetype = "TextCF";
          break;
        case "textarea":
          outField.resourcetype = "TextCF";
          outField.multiline = true;
          break;
        case "email":
          outField.resourcetype = "EmailCF";
          break;
        case "tel":
          outField.resourcetype = "PhoneCF";
          break;
        case "url":
          outField.resourcetype = "LinkCF";
          break;
        case "number":
          outField.resourcetype = "IntegerCF";
          if (field.min) outField.min = field.min;
          if (field.max) outField.max = field.max;
          break;
        case "float":
          outField.resourcetype = "FloatCF";
          if (field.min) outField.min = field.min;
          if (field.max) outField.max = field.max;
          break;
        case "date":
          outField.resourcetype = "DateTimeCF";
          outField.datetime_type = "DATE";
          break;
        case "time":
          outField.resourcetype = "DateTimeCF";
          outField.datetime_type = "TIME";
          break;
        case "file":
          outField.resourcetype = "FileCF";
          if (field.fileTypes?.length)
            outField.filename_extensions = field.fileTypes.filter(val => val);
          if (field.maxSize) outField.file_volume = field.maxSize;
          break;
        case "checkbox":
          outField.resourcetype = "BooleanCF";
          if (field.button) outField.button = !!field.button;
          break;
        case "checkbox_group":
          outField.resourcetype = "ChoicesCF";
          outField.type = "CHECKBOX";
          outField.buttons = !!field.button;
          outField.inline = !!field.inline;
          outField.choices = field.items
            ? field.items.map((val, index) => {
                let outChoice = {
                  title: val.label_ru,
                  title_ru: val.label_ru,
                  title_en: val.label_en
                };
                if (val.value) {
                  outChoice.id = val.id;
                  outChoice.deleted = !!val.deleted;
                  outChoice.order = index;
                  if (val.id !== val.value) outChoice.value = val.value;
                }
                return outChoice;
              })
            : [];
          break;
        case "radio_buttons":
          outField.resourcetype = "ChoicesCF";
          outField.type = "RADIOBUTTONS";
          outField.buttons = !!field.button;
          outField.inline = !!field.inline;
          if (field.select) {
            outField.type = "SELECT";
          }
          outField.choices = field.items
            ? field.items.map((val, index) => {
                let outChoice = {
                  title: val.label_ru,
                  title_ru: val.label_ru,
                  title_en: val.label_en
                };
                if (val.value) {
                  outChoice.id = val.id;
                  outChoice.deleted = !!val.deleted;
                  outChoice.order = index;
                  if (val.id !== val.value) outChoice.value = val.value;
                }
                return outChoice;
              })
            : [];
          break;
      }
      return outField;
    }
  },
  modules: {
    auth
  },
  getters: {
    language: state => state.language,
    loading: state => state.loading,
    headerImage: state => state.headerImage,
    headerTitle: state => state.headerTitle,
    eventNames: state => state.eventNames,
    eventName: state => id =>
      state.eventNames.find(val => val.value === id)?.label || id,
    eventArticleTypes: state => state.eventArticleTypes,
    eventArticleType: state => id =>
      state.eventArticleTypes.find(val => val.value === id)?.label || id,
    defaultFields: state => state.defaultFields,
    requestStatuses: state => state.requestStatuses,
    requestStatus: state => id =>
      state.requestStatuses.find(val => val.id === id)?.title || id,
    requestStatusIcons: state => state.requestStatusIcons,
    requestStatusIcon: state => id =>
      state.requestStatusIcons.find(val => val.id === id)?.icon || "question",
    requestOperationTypes: state => state.requestOperationTypes,
    requestOperationType: state => id =>
      state.requestOperationTypes.find(val => val.id === id)?.title || id,
    htmlTitle: state =>
      state.tabTitle
        ? `${state.tabTitle} - ${state.tabTitleBase}`
        : state.tabTitleBase
  }
});
