// eslint-disable-next-line no-unused-vars
import { API_TIMEOUT, API_URL } from "./config";
import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";
import { setup } from "axios-cache-adapter";
import store from "@/store";

let axios = null;

async function configure() {
  await localforage.defineDriver(memoryDriver);
  const forageStore = localforage.createInstance({
    driver: [
      localforage.INDEXEDDB,
      // localforage.LOCALSTORAGE,
      memoryDriver._driver
    ],
    name: "reg-cache"
  });

  return setup({
    baseURL: API_URL,
    timeout: API_TIMEOUT,
    cache: {
      maxAge: 0,
      store: forageStore,
      exclude: { query: false },
      key: req => {
        let serialized =
          req.params instanceof URLSearchParams
            ? req.params.toString()
            : JSON.stringify(req.params) || "";
        return req.url + serialized;
      }
    }
  });
}

const ApiService = {
  async init() {
    return (axios = await configure());
  },

  setAuthorizationHeader(token) {
    axios.defaults.headers.common["Authorization"] = `JWT ${token}`;
  },

  deleteAuthorizationHeader() {
    delete axios.defaults.headers.common["Authorization"];
  },

  setLangHeader(lang = "ru") {
    axios.defaults.headers.common["Accept-Language"] = lang ? lang : "ru";
  },

  deleteLangHeader() {
    delete axios.defaults.headers.common["Accept-Language"];
  },

  async get(resource, slug = "", cacheTimeMinutes = 0, timeout = API_TIMEOUT) {
    let url = resource.trim();
    if (slug !== "") {
      url += `${slug}/`;
    }
    if (cacheTimeMinutes === 0) {
      return await axios.get(url).catch(e => {
        if (e.response.status === 404) store.commit("UPDATE_NOT_FOUND", true);
        throw e;
      });
    } else if (cacheTimeMinutes === -1) {
      return await axios
        .get(url, {
          timeout: timeout,
          cache: {
            ignoreCache: true
          }
        })
        .then(res => {
          if (!store.getters.language)
            store.commit(
              "UPDATE_LANGUAGE",
              res.headers.get("content-language")
            );
          return res;
        })
        .catch(e => {
          if (e.response.status === 404) store.commit("UPDATE_NOT_FOUND", true);
          throw e;
        });
    } else {
      return await axios
        .get(url, {
          timeout: timeout,
          cache: {
            maxAge: cacheTimeMinutes * 60 * 1000
          }
        })
        .catch(e => {
          if (e.response.status === 502) {
            store.commit("UPDATE_UNDER_DEV", true);
            return;
          }
          if (e.response.status === 404) store.commit("UPDATE_NOT_FOUND", true);
          throw e;
        });
    }
  },

  post(resource, params, options = {}) {
    return axios.post(`${resource.trim()}`, params, options);
  },

  delete(resource, options = {}) {
    return axios.delete(`${resource.trim()}`, options);
  },

  patch(resource, params, options = {}) {
    return axios.patch(`${resource.trim()}`, params, options);
  }
};

export default ApiService;
