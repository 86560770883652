import ApiService from "../api.service";

export default {
  getEventRequestStatuses() {
    return ApiService.get(`event_request/statuses/`, "", 1440);
  },
  getEventRequestOperationTypes() {
    return ApiService.get(`event_request/operation_types/`, "", 1440);
  },
  getEventRequestOperations(id, page) {
    return ApiService.get(
      `event_request/operations/${id}/?page=${page}`,
      "",
      0
    );
  },
  createEventRequestOperation(id, data) {
    return ApiService.post(`event_request/operations/${id}/create/`, data);
  },
  eventRequestCreate(data) {
    return ApiService.post(`event_request/create/`, data);
  },
  getEventRequestDetails(id) {
    return ApiService.get(`event_request/details/`, id, 0);
  },
  eventRequestEdit(id, data) {
    return ApiService.post(`event_request/edit/${id}/`, data);
  },
  eventRequestDelete(id) {
    return ApiService.delete(`event_request/delete/${id}`);
  }
};
