import ApiService from "../api.service";

export default {
  getEventNames() {
    return ApiService.get(`event/event_types/`, "", 1440);
  },
  getEventExportLink(slug) {
    return ApiService.get(`event/export/`, slug, 0);
  },
  getEventArticleTypes() {
    return ApiService.get(`event/participation_types/`, "", 1440);
  },
  getDefaultFields() {
    return ApiService.get(`event/default_fields/`, "", 1440);
  },
  getEventList(page, search = "") {
    let url = `event/list/?page_size=10&page=${page}`;
    if (search) url += `&search=${encodeURI(search)}`;
    return ApiService.get(url, "", 0);
  },
  eventCreate(data) {
    return ApiService.post(`event/create/`, data);
  },
  getEventDetails(slug) {
    return ApiService.get(`event/details/`, slug, 0);
  },
  getEventStatistic(slug, filters = {}) {
    let url = `event/statistic/${slug}/`;
    let filterString = "";
    Object.entries(filters).forEach(([key, value]) => {
      if (!value) return;
      if (value.isArray) {
        value = value.join(",");
      }
      value = `${value}`;
      filterString += `&${key}=${encodeURI(value)}`;
    });
    if (filterString) url += `?${filterString}`;
    return ApiService.get(url, "", 0);
  },
  getEventInnerRequestList(slug, page, pageSize, filters = {}) {
    let url = `event/requests/${slug}/?page=${page}&page_size=${pageSize}`;
    let filterString = "";
    Object.entries(filters).forEach(([key, value]) => {
      if (!value) return;
      if (value.isArray) {
        value = value.join(",");
      }
      value = `${value}`;
      filterString += `&${key}=${encodeURI(value)}`;
    });
    if (filterString) url += `?${filterString}`;
    return ApiService.get(url, "", 0);
  },
  eventEdit(slug, data) {
    return ApiService.patch(`event/edit/${slug}/`, data);
  },
  getEventAdminDetails(slug) {
    return ApiService.get(`event/edit/`, slug, 0);
  },
  eventDelete(slug) {
    return ApiService.delete(`event/delete/${slug}`);
  }
};
