<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import wsMixin from "./mixins/wsMixin";
import NewOperation from "./components/toasts/NewOperation";
import scrollMixin from "@/mixins/scrollMixin";

const EmptyLayout = () => import("@/layouts/EmptyLayout");
const MainLayout = () => import("@/layouts/MainLayout");

export default {
  name: "App",
  mixins: [wsMixin, scrollMixin],
  computed: {
    ...mapGetters(["auth", "jwt", "user"]),
    layout() {
      return (this.$route.meta.layout || "Empty") + "Layout";
    }
  },
  components: {
    EmptyLayout,
    MainLayout
  },
  methods: {
    changeScrollNow() {
      this.scrollNow = pageYOffset;
    },
    async rcvMsg(e) {
      let ws_data = JSON.parse(e.data);
      let content = ws_data.content;
      let message_type = ws_data.message_type;
      this.wsLastData = ws_data;
      switch (message_type) {
        case "new_message":
          if (
            this.$route.name !== "NoteOperations" ||
            (this.$route.name === "NoteOperations" &&
              `${this.$route.params?.id}` !== `${content?.request}`)
          ) {
            this.$toast.info(
              {
                component: NewOperation,
                props: {
                  message: content
                }
              },
              {
                closeOnClick: false
              }
            );
          }
          break;
        case "unread_messages":
          this.unreadMessages = content.count || 0;
          break;
        default:
          break;
      }
    },
    initWs() {
      if (!this.ws) {
        let jwt_token_ws = "";
        // const stored_jwt = this.jwt;
        // if (this.auth && stored_jwt) {
        //   jwt_token_ws = "?token=" + stored_jwt;
        // }
        // eslint-disable-next-line no-unused-vars
        const wsScheme = window.location.protocol === "https:" ? "wss" : "ws";
        const hostname = window.location.hostname;
        const port = window.location.port;
        let ws = new WebSocket(
          `${wsScheme}://${hostname}:${port}/ws/${jwt_token_ws}`
        );
        // let ws = new WebSocket(
        //   // `${wsScheme}://${window.location.protocol}/ws${jwt_token_ws}`
        // );
        ws.onmessage = this.rcvMsg;
        ws.onopen = () => {
          // console.log("WS open");
          // this.checkConnectivity();
          this.sign_in_ws();
        };
        ws.onclose = () => {
          // console.log("WS reconnect");
          this.reconnect();
        };
        ws.onerror = () => {
          // console.log("WS close");
          ws.close();
          this.reconnect();
        };
        this.ws = ws;
      }
    },
    async reconnect() {
      if (this.ws) {
        await this.ws.close();
        this.ws = null;
      } else {
        // console.log("WS not connected");
        setTimeout(() => {
          this.initWs();
        }, 1000);
      }
    },
    async sign_in_ws() {
      if (!this.ws) {
        await this.initWs();
      }
      if (this.auth)
        this.ws.send(
          JSON.stringify({
            message_type: "sign_in",
            token: this.jwt
          })
        );
    },
    async sign_out_ws() {
      if (!this.ws) {
        await this.initWs();
      }
      this.ws.send(
        JSON.stringify({
          message_type: "sign_out"
        })
      );
    },
    async checkConnectivity() {
      // console.log("WS start check");
      // await setTimeout(() => {
      //   console.log(`WS connection ${this.ws.connected}`);
      //   console.log("WS end check");
      //   this.checkConnectivity();
      // }, 1000);
    },
    async tryConnect() {}
  },
  async created() {
    // if (this.auth) {
    this.initWs();
    // }
  },
  mounted() {
    this.changeScrollNow();
    window.addEventListener("scroll", this.changeScrollNow);
  },
  watch: {
    async auth(val) {
      if (val) {
        await this.sign_in_ws();
        if (!this.user.email_confirm) {
          this.$toast.warning(
            "Вы ещё не подтвердили свой email. Если вы не получили письмо, то отправьте подтверждение ещё раз из личного кабинета"
          );
        }
      } else {
        await this.sign_out_ws();
        this.unreadMessages = 0;
      }
    }
  }
};
</script>
