import ApiService from "../api.service";
import user from "./user";
import event from "./event";
import event_request from "./event_request";

export default {
  signIn(payload) {
    return ApiService.post("user/signin/", payload);
  },
  signUp(payload) {
    return ApiService.post("user/reg/", payload);
  },
  ...user,
  ...event,
  ...event_request
};
