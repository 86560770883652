export default {
  loading: "Loading",
  search: "Search",
  yes: "yes",
  no: "no",
  link: "Open in this tab",
  linkBlank: "Open in new tab",
  number: "#",
  noValue: "-No value-",
  noField: "-Field not found-",
  about: {
    university: "Bauman Moscow State Technical University",
    organization: "Department of Information and Youth Policy"
  },
  event: {
    fields: {
      title: "Title",
      description: "Description",
      shortDescription: "Short Description",
      descriptionHtml: "Description preview",
      required: "required field",
      urlAccept: ["For acquaintance", "follow the link"]
    },
    texts: {
      zeroRequests: "Applications not found",
      section: "Section",
      sections: "Sections",
      sectionDescription: "Section Description",
      statistic: "Statistic",
      members: "Members",
      columns: "Columns",
      requestsResults: "Results",
      requests: "Applications",
      articles: "Articles"
    },
    actions: {
      delete: "Delete",
      edit: "Edit",
      restore: "Restore",
      cancel: "Cancel",
      save: "Save",
      export: "Export",
      apply: "Apply for participation",
      clear: "Clear",
      send: "Send"
    },
    section: {
      edit: {
        title: "Section edit",
        choose: "Choose section",
        choose2: "Which section?"
      }
    },
    edit: {
      title: "Event edit"
    },
    create: {
      title: "New event"
    },
    list: {
      title: "Events",
      past: "Past events",
      future: "Future events",
      empty: "Events not found"
    },
    short: {
      onlyLink: "Only by link",
      draft: "Draft"
    },
    details: {
      title: "Event",
      mainInfo: "Main Information",
      type: "Type",
      eventDatesRequest: "Filing dates",
      eventDates: "Date of the event",
      site: "Site",
      review: "Peer review",
      escort: "Accompaniment",
      withEscort: "with accompaniment",
      withoutEscort: "without accompaniment",
      participationType: "Participation Type",
      requestsCount: "Applications quantity"
    },
    request: {
      create: {
        title: "New application"
      },
      details: {
        title: "Application"
      },
      operations: {
        title: "Application operations",
        name: "Operations",
        nameFirst: "",
        nameSecond: "Operations",
        inbox: "Inbox",
        sent: "Sent",
        read: "Read",
        unread: "Unread",
        requestId: "Application # {id}",
        send: "Send",
        sendHelp: "send message",
        operationType: "Operation type",
        newStatus: "New status",
        created: "Created",
        updated: "Updated"
      },
      texts: {
        status: "Status",
        id: "Application id",
        downloadFile: "Download file"
      }
    }
  },
  user: {
    signIn: {
      title: "Sign In"
    },
    signOut: {
      title: "Sign Out"
    },
    signUp: {
      title: "Sign Up"
    },
    resetPass: {
      title: "Reset Password"
    },
    profile: {
      title: "Personal Area"
    },
    fields: {
      lastName: "Last Name",
      firstName: "First Name",
      middleName: "Middle Name",
      sex: "Sex",
      sexItems: {
        male: "Male",
        female: "Female"
      },
      birthday: "Birthday",
      phone: "Phone",
      email: "Email",
      password: "Password",
      password2: "Repeat Password",
      authorize: "Sign In",
      resetPass: "Reset Password",
      send: "Send"
    },
    texts: {
      changeAvatar: "You can change avatar",
      setAvatar: "You can set avatar"
    }
  },
  instruction: {
    title: "Instruction"
  },
  contacts: {
    title: "Contacts"
  },
  markdownPage: {
    personalData: "Consent to Personal Data Processing",
    privatePolicy: "Private Policy"
  },
  header: {
    events: "events",
    contacts: "contacts",
    instruction: "instruction",
    signOut: "sign out",
    signIn: "sign in",
    signUp: "sign up",
    personalArea: "personal area"
  },
  validatorMessages: {
    alpha: "Поле может содержать только буквы",
    alpha_dash: "Поле может содержать только буквы, цифры и дефис",
    alpha_num: "Поле может содержать только буквы и цифры",
    alpha_spaces: "Поле может содержать только буквы и пробелы",
    between: "Поле должно быть между {min} и {max}",
    confirmed: "Поле не совпадает с {target}",
    digits: "Поле должно быть числовым и длиной {length}",
    dimensions: "Поле должно быть {width} пикселей на {height} пикселей",
    email: "Поле должно быть действительным электронным адресом",
    excluded: "Поле должно быть допустимым значением",
    ext: "Поле должно быть действительным файлом.",
    image: "Поле должно быть изображением",
    oneOf: "Поле должно быть допустимым значением",
    integer: "Поле должно быть целым числом",
    length: "Длина поля должна быть {length}",
    max: "Поле не может быть более {length} символов",
    max_value: "Поле должно быть {max} или менее",
    mimes: "Поле должно иметь допустимый тип файла.",
    min: "Поле должно быть не менее {length} символов",
    min_value: "Поле должно быть {min} или больше",
    numeric: "Поле должно быть числом",
    regex: "Поле имеет ошибочный формат",
    required: "This field is required",
    required_if: "This field is required",
    is: 'Field should be equal with "{field}" field',
    tel: "Phone not correct",
    size: "Поле должно быть меньше, чем {size}KB"
  },
  field: {
    help: {
      fileTypes: "Compatible file types",
      maxSize: "Max file size",
      sizeMb: "Mb",
      length: "Field length"
    }
  },
  toast: {
    privateInfo: [
      "By continuing to use our site, you",
      "consent to personal data processing",
      " and also agree to the",
      "privacy policy",
      ".",
      "If you do not want your data to be processed, leave the site.",
      "Ok"
    ],
    newOperation: {
      text: "New operation in application #{id}",
      button: "Show"
    }
  }
};
