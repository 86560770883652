import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const markdownPage = () =>
  import(/* webpackChunkName: "markdown_page" */ "../views/MarkdownPage");

const signInComponent = () =>
  import(/* webpackChunkName: "signin" */ "../views/SignIn");

const eventUpdateCreate = () =>
  import(/* webpackChunkName: "event_update_create" */ "../views/EventEdit");

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "event_list" */ "../views/Home"),
    meta: {
      layout: "Main",
      title: "event.list.title",
      headerTitle: "event.list.title",
      headerImage: require("@/assets/images/home.jpg")
    }
  },
  {
    path: "/personaldata",
    name: "PersonalData",
    component: markdownPage,
    meta: {
      layout: "Main",
      title: "markdownPage.personalData",
      headerImage: require("@/assets/images/contacts.jpg"),
      markdown: "personaldata",
      headerTitle: "markdownPage.personalData"
    }
  },
  {
    path: "/policy",
    name: "Policy",
    component: markdownPage,
    meta: {
      layout: "Main",
      title: "markdownPage.privatePolicy",
      headerImage: require("@/assets/images/contacts.jpg"),
      markdown: "policy",
      headerTitle: "markdownPage.privatePolicy"
    }
  },
  {
    path: "/instruction",
    name: "Instruction",
    component: markdownPage,
    meta: {
      layout: "Main",
      title: "instruction.title",
      headerImage: require("@/assets/images/instruction.jpg"),
      markdown: "instruction",
      headerTitle: "instruction.title"
    }
  },
  {
    path: "/signin",
    name: "SignIn",
    component: signInComponent,
    meta: {
      layout: "Main",
      title: "user.signIn.title",
      headerImage: require("@/assets/images/auth.jpg"),
      onlyGuest: true
    }
  },
  {
    path: "/reset_pass",
    name: "ResetPass",
    component: signInComponent,
    meta: {
      layout: "Main",
      title: "user.resetPass.title",
      headerImage: require("@/assets/images/auth.jpg"),
      onlyGuest: true
    }
  },
  {
    path: "/signup",
    name: "SignUp",
    component: () => import(/* webpackChunkName: "signup" */ "../views/SignUp"),
    meta: {
      layout: "Main",
      title: "user.signUp.title",
      headerImage: require("@/assets/images/auth.jpg"),
      onlyGuest: true
    }
  },
  // {
  //   path: "/instruction",
  //   name: "Instruction",
  //   component: () =>
  //     import(/* webpackChunkName: "instruction" */ "../views/Instruction"),
  //   meta: {
  //     layout: "Main",
  //     title: "instruction.title",
  //     headerTitle: "instruction.title",
  //     headerImage: require("@/assets/images/instruction.jpg")
  //   }
  // },
  {
    path: "/contacts",
    name: "Contacts",
    component: () =>
      import(/* webpackChunkName: "contacts" */ "../views/Contacts"),
    meta: {
      layout: "Main",
      title: "contacts.title",
      headerTitle: "contacts.title",
      headerImage: require("@/assets/images/contacts.jpg")
    }
  },
  {
    path: "/event/new_application/:slug",
    name: "NewMember",
    component: () =>
      import(/* webpackChunkName: "request_create" */ "../views/NewMember"),
    meta: {
      title: "event.request.create.title",
      layout: "Main",
      reuse: false
    }
  },
  {
    path: "/event/:slug",
    name: "EventDetails",
    component: () =>
      import(/* webpackChunkName: "event" */ "../views/EventDetails"),
    meta: {
      title: "event.details.title",
      layout: "Main",
      reuse: false
    }
  },
  {
    path: "/event/:slug/edit",
    name: "EventEdit",
    component: eventUpdateCreate,
    meta: {
      title: "event.edit.title",
      layout: "Main",
      onlyAuth404: true
    }
  },
  {
    path: "/new_event",
    name: "EventNew",
    component: eventUpdateCreate,
    meta: {
      title: "event.create.title",
      layout: "Main"
    }
  },
  {
    path: "/application/:id",
    name: "NoteDetails",
    component: () =>
      import(/* webpackChunkName: "request_details" */ "../views/NoteDetails"),
    meta: {
      title: "event.request.details.title",
      layout: "Main"
    }
  },
  {
    path: "/application/:id/operations",
    name: "NoteOperations",
    component: () =>
      import(
        /* webpackChunkName: "request_operations" */ "../views/RequestOperations"
      ),
    meta: {
      title: "event.request.operations.title",
      layout: "Main",
      withoutImage: true,
      withoutScroll: true
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () =>
      import(/* webpackChunkName: "private_area" */ "../views/PrivateArea"),
    meta: {
      layout: "Main",
      title: "user.profile.title",
      headerTitle: "user.profile.title",
      headerImage: require("@/assets/images/error.jpg"),
      onlyAuth: true
    }
  },
  {
    path: "/action/url/:uuid/:uuid2/",
    name: "ActionLink",
    component: () =>
      import(/* webpackChunkName: "action_link" */ "../views/ActionLink"),
    meta: {
      layout: "Main"
    }
  },
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "not_found" */ "../views/NotFound"),
    meta: {
      layout: "Main"
    }
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About')
  // }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// eslint-disable-next-line no-unused-vars
router.beforeEach(async (to, from, next) => {
  const promises = [];
  if (to.meta.reuse !== false || to.path !== from.path) {
    promises.push(
      ...[
        store.commit("UPDATE_LOADING", 0),
        store.commit("UPDATE_NOT_FOUND", false),
        store.commit("UPDATE_HIDE_ALL", true),
        store.dispatch("scrollToTop"),
        store.commit("UPDATE_HEADER_IMAGE", to.meta?.headerImage || null),
        store.commit("UPDATE_HEADER_TITLE", to.meta?.headerTitle || null),
        store.commit("UPDATE_TAB_TITLE", to.meta?.title || null)
      ]
    );
  }
  await Promise.all(promises);
  if (!store.getters.auth) {
    if (to.meta.onlyAuth) {
      next({ name: "SignIn" });
      return;
    }
    if (to.meta.onlyAuth404) {
      store.commit("UPDATE_NOT_FOUND", true);
    }
  }
  if (to.meta.onlyGuest && store.getters.auth) {
    next({ name: "Home" });
    return;
  }
  const old_token = store.getters.jwt;
  await store.dispatch("inspectToken");
  if (old_token !== store.getters.jwt) location.reload();
  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach(async (to, from) => {
  store.commit("UPDATE_HIDE_ALL", false);
});

export default router;
