export default {
  computed: {
    ws: {
      get() {
        return this.$store.state.ws;
      },
      set(value) {
        return this.$store.commit("UPDATE_WS", value);
      }
    },
    unreadMessages: {
      get() {
        return this.$store.state.unreadMessages;
      },
      set(value) {
        return this.$store.commit("UPDATE_UNREAD_MESSAGES", value);
      }
    },
    wsLastData: {
      get() {
        return this.$store.state.wsLastData;
      },
      set(value) {
        return this.$store.commit("UPDATE_WS_LAST_DATA", value);
      }
    }
  }
};
