import VueI18n from "vue-i18n";
import ru from "./messages/ru";
import en from "./messages/en";
import Vue from "vue";

const i18nOptions = {
  locale: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "ru",
  messages: {
    ru,
    en
  }
};
Vue.use(VueI18n);
export default new VueI18n(i18nOptions);
