export default {
  loading: "Загрузка",
  search: "Поиск",
  yes: "да",
  no: "нет",
  link: "Открыть в этом окне",
  linkBlank: "Открыть в новом окне",
  number: "№",
  noValue: "-Нет значения-",
  noField: "-Поле не найдено-",
  about: {
    university: "МГТУ им. Н.Э. Баумана",
    organization: "Управление информационной и молодежной политики"
  },
  event: {
    fields: {
      title: "Заголовок",
      description: "Описание",
      shortDescription: "Краткое описание",
      descriptionHtml: "отображение описания",
      required: "обязательное поле",
      urlAccept: ["Для ознакомления", "перейдите по ссылке"]
    },
    texts: {
      zeroRequests: "Нет заявок",
      section: "Секция",
      sections: "Секции",
      sectionDescription: "Описание секции",
      statistic: "Статистика",
      members: "Участники",
      columns: "Столбцы",
      requestsResults: "Результаты выборки",
      requests: "Заявки",
      articles: "Статьи"
    },
    actions: {
      delete: "Удалить",
      edit: "Редактировать",
      restore: "Восстановить",
      cancel: "Отменить",
      save: "Сохранить",
      export: "Экспорт",
      apply: "Подать заявку",
      clear: "Очистить",
      send: "Отправить"
    },
    section: {
      edit: {
        title: "Редактирование секции",
        choose: "Выберите секцию",
        choose2: "На какую секцию?"
      }
    },
    edit: {
      title: "Редактирование мероприятия"
    },
    create: {
      title: "Новое мероприятие"
    },
    list: {
      title: "Мероприятия",
      past: "Прошедшие мероприятия",
      future: "Будущие мероприятия",
      empty: "Мероприятия отсутствуют"
    },
    short: {
      onlyLink: "Только по ссылке",
      draft: "Черновик"
    },
    details: {
      title: "Мероприятие",
      mainInfo: "Основные данные",
      type: "Тип",
      eventDatesRequest: "Дата подачи заявок",
      eventDates: "Дата проведения",
      site: "Сайт",
      review: "Рецензирование",
      escort: "Сопровождение",
      withEscort: "c сопровождением",
      withoutEscort: "без сопровождения",
      participationType: "Тип участия",
      requestsCount: "Количество заявок"
    },
    request: {
      create: {
        title: "Новая заявка"
      },
      details: {
        title: "Заявка"
      },
      operations: {
        title: "Операции заявки",
        name: "Операции",
        nameFirst: "Операции",
        nameSecond: "",
        inbox: "Входящие",
        sent: "Исходящие",
        read: "Прочитано",
        unread: "Не прочитано",
        requestId: "заявки № {id}",
        send: "Отправить",
        sendHelp: "отправить сообщение",
        operationType: "Тип операции",
        newStatus: "Новый статус",
        created: "Создана",
        updated: "Обновлена"
      },
      texts: {
        status: "Статус",
        id: "Номер заявки",
        downloadFile: "Скачать файл"
      }
    }
  },
  user: {
    signIn: {
      title: "Вход"
    },
    signOut: {
      title: "Выход"
    },
    signUp: {
      title: "Регистрация"
    },
    resetPass: {
      title: "Восстановление пароля"
    },
    profile: {
      title: "Личный кабинет"
    },
    fields: {
      lastName: "Фамилия",
      firstName: "Имя",
      middleName: "Отчество",
      sex: "Пол",
      sexItems: {
        male: "Мужчина",
        female: "Женщина"
      },
      birthday: "Дата рождения",
      phone: "Телефон",
      email: "Электронная почта",
      password: "Пароль",
      password2: "Повторите пароль",
      authorize: "Авторизоваться",
      resetPass: "Восстановить пароль",
      send: "Отправить"
    },
    texts: {
      changeAvatar: "Вы можете сменить аватар",
      setAvatar: "Вы можете установить аватар"
    }
  },
  instruction: {
    title: "Инструкция"
  },
  contacts: {
    title: "Контакты"
  },
  markdownPage: {
    personalData: "Согласие на обработку персональных данных",
    privatePolicy: "Политика конфиденциальности"
  },
  header: {
    events: "мероприятия",
    contacts: "контакты",
    instruction: "инструкция",
    signOut: "выход",
    signIn: "вход",
    signUp: "регистрация",
    personalArea: "личный кабинет"
  },
  validatorMessages: {
    alpha: "Поле может содержать только буквы",
    alpha_dash: "Поле может содержать только буквы, цифры и дефис",
    alpha_num: "Поле может содержать только буквы и цифры",
    alpha_spaces: "Поле может содержать только буквы и пробелы",
    between: "Поле должно быть между {min} и {max}",
    confirmed: "Поле не совпадает с {target}",
    digits: "Поле должно быть числовым и длиной {length}",
    dimensions: "Поле должно быть {width} пикселей на {height} пикселей",
    email: "Поле должно быть действительным электронным адресом",
    excluded: "Поле должно быть допустимым значением",
    ext: "Поле должно быть действительным файлом.",
    image: "Поле должно быть изображением",
    oneOf: "Поле должно быть допустимым значением",
    integer: "Поле должно быть целым числом",
    length: "Длина поля должна быть {length}",
    max: "Поле не может быть более {length} символов",
    max_value: "Поле должно быть {max} или менее",
    mimes: "Поле должно иметь допустимый тип файла.",
    min: "Поле должно быть не менее {length} символов",
    min_value: "Поле должно быть {min} или больше",
    numeric: "Поле должно быть числом",
    regex: "Поле имеет ошибочный формат",
    required: "Поле обязательно для заполнения",
    required_if: "Поле обязательно для заполнения",
    is: 'Поле должно совпадать с полем "{field}"',
    tel: "Телефон должен быть верного формата",
    size: "Поле должно быть меньше, чем {size}KB"
  },
  field: {
    help: {
      fileTypes: "Поддерживаемые типы файлов",
      maxSize: "Максимальный размер файла",
      sizeMb: "Мб",
      length: "Длина поля"
    }
  },
  toast: {
    privateInfo: [
      "Продолжая использовать наш сайт, вы даёте",
      "согласие на обработку персональных данных",
      ", а также согласны с",
      "политикой конфиденциальности",
      ".",
      "Если вы не хотите, чтобы ваши данные обрабатывались, покиньте сайт.",
      "Понятно"
    ],
    newOperation: {
      text: "Новая операция по заявке №{id}",
      button: "Посмотреть"
    }
  }
};
