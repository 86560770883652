<template>
  <div class="d-flex flex-column align-items-end">
    <div>
      {{ i18n.t("toast.newOperation.text", { id: message.request }) }}
    </div>
    <div class="mt-2">
      <button class="btn btn-outline-light" @click.stop="clicked">
        {{ i18n.t("toast.newOperation.button") }}
      </button>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import i18n from "@/i18n";

export default {
  name: "NewOperation",
  data: () => ({
    router,
    i18n
  }),
  props: {
    toastId: {
      type: [String, Number],
      required: true
    },
    message: {
      type: Object
      // required: true
    }
  },
  methods: {
    clicked() {
      router.push({
        name: "NoteOperations",
        params: { id: this.message.request }
      });
      this.$emit("close-toast");
    }
  }
};
</script>
