import ApiService from "../api.service";

export default {
  getUserInfo(id) {
    return ApiService.get(`user/info/${id}/`);
  },
  getUser() {
    return ApiService.get(`user/`);
  },
  getContacts() {
    return ApiService.get(`contacts/`);
  },
  patchUser(data) {
    return ApiService.patch(`user/`, data);
  },
  sendConfirm() {
    return ApiService.get(`user/send_confirm/`, "", 0);
  },
  actionLink(data) {
    return ApiService.post(`user/action_link/`, data);
  },
  resetPassword(data) {
    return ApiService.post(`user/reset_pass/`, data);
  },
  getUserRequestList(page, pageSize, filters = {}) {
    let url = `user/requests/?page=${page}&page_size=${pageSize}`;
    let filterString = "";
    Object.entries(filters).forEach(([key, value]) => {
      if (!value) return;
      if (value.isArray) {
        value = value.join(",");
      }
      value = `${value}`;
      filterString += `&${key}=${encodeURI(value)}`;
    });
    if (filterString) url += `${filterString}`;
    return ApiService.get(url, "", 0);
  },
  getUserList(page, pageSize, filters = {}) {
    let url = `user/list/?page=${page}&page_size=${pageSize}`;
    let filterString = "";
    Object.entries(filters).forEach(([key, value]) => {
      if (!value) return;
      if (value.isArray) {
        value = value.join(",");
      }
      value = `${value}`;
      filterString += `&${key}=${encodeURI(value)}`;
    });
    if (filterString) url += `${filterString}`;
    return ApiService.get(url, "", 0);
  }
};
