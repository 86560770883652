export default {
  computed: {
    scrollNow: {
      get() {
        return this.$store.state.scrollNow;
      },
      set(val) {
        this.$store.commit("UPDATE_SCROLL_NOW", val);
      }
    },
    footerBoundingSize: {
      get() {
        return this.$store.state.footerBoundingSize;
      },
      set(val) {
        this.$store.commit("UPDATE_FOOTER_BOUNDING_SIZE", val);
      }
    }
  }
};
