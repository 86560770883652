import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import i18n from "./i18n";
import VModal from "vue-js-modal";
import Toast from "vue-toastification";

import "vue-tel-input/dist/vue-tel-input.css";
import VueTelInput from "vue-tel-input";
import "./styles/fonts/Gilroy.css";
import "./styles/fonts/Muller.css";
import "./styles/bootstrap.scss";
import "vue-toastification/src/scss/index.scss";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
// import "bootstrap/scss/bootstrap.scss";
// import "bootstrap/scss/bootstrap-grid.scss";
// import "bootstrap/scss/bootstrap-utilities.scss";
import VueLazyload from "vue-lazyload";
import VCalendar from "v-calendar";
import ApiService from "./common/api.service";

Vue.config.productionTip = false;
ApiService.init().then(async () => {
  await Promise.all([
    store.dispatch("updateLangFromLocalStorage"),
    store.dispatch("fetchDefaultFields"),
    store.dispatch("fetchEventNames"),
    store.dispatch("fetchEventArticleTypes"),
    store.dispatch("fetchEventRequestStatuses"),
    store.dispatch("fetchEventRequestOperationTypes")
  ]);
  const router = require("./router").default;
  const token = store.getters.jwt;

  if (token) {
    ApiService.setAuthorizationHeader(token);
  }

  // TODO: Сделать проверку присутствия и отсутствия авторизации
  // if (store.getters.auth) {
  //   if (router.meta.onlyAuth) {
  //     console.log("HS");
  //     router.push({ name: "SignIn" });
  //     return;
  //   }
  //   if (to.meta.onlyAuth404) {
  //     store.commit("UPDATE_NOT_FOUND", true);
  //     return;
  //   }
  // }
  // if (to.meta.onlyGuest && !store.getters.auth) {
  //   router.push({ name: "Home" });
  //   return;
  // }

  Vue.use(VueLazyload);
  Vue.use(VModal, {
    dynamicDefault: {
      draggable: ".modal-header",
      adaptive: true,
      height: "auto",
      scrollable: true
    }
  });
  Vue.use(Toast, {});

  Vue.use(VueTelInput, {
    dropdownOptions: {
      showDialCodeInList: true
      // showDialCodeInSelection: true,
    },
    validCharactersOnly: true,
    inputOptions: {
      type: "tel"
    },
    autoFormat: true,
    mode: "international"
  });

  // Use v-calendar & v-date-picker components
  Vue.use(VCalendar, {
    componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
  });

  const tracesSampleRate = process.env.NODE_ENV === "production" ? 0.2 : 1.0;

  Sentry.init({
    Vue,
    dsn:
      "https://d4d61ee5d60b4810b790cd3b663d5ee2@o977453.ingest.sentry.io/5962628",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: tracesSampleRate,
    environment: process.env.NODE_ENV
  });

  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
});
