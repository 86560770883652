import jwt_decode from "jwt-decode";
import ApiService from "@/common/api.service";
import API from "@/common/api/api.functions";
import router from "@/router";

export default {
  state: {
    jwt: localStorage.getItem("token"),
    auth: !!localStorage.getItem("token"),
    endpoints: {
      refreshJWT: "/user/token-refresh/",
      verifyJWT: "/user/token-verify/"
    },
    user: JSON.parse(localStorage.getItem("user")) || null,
    bkInfoLoaded: false,
    firstSignIn: false,
    newSignUp: false,
    signInModal: false
  },
  mutations: {
    UPDATE_AUTH(state, value) {
      state.auth = value;
    },
    UPDATE_TOKEN(state, newToken) {
      localStorage.setItem("token", newToken);
      state.jwt = newToken;
    },
    UPDATE_SIGNIN_MODAL(state, value) {
      state.signInModal = value;
    },
    UPDATE_USER(state, user) {
      state.user = user;
      localStorage.setItem("user", JSON.stringify(state.user));
      if (localStorage.getItem("language") !== user.language) {
        state.loading = true;
        // indexedDB.deleteDatabase("reg-cache");
        state.language = user.language;
        localStorage.setItem("language", user.language);

        const request = window.indexedDB.open("reg-cache");
        request.onsuccess = function(event) {
          let db = event.target.result;
          let store = db
            .transaction(["keyvaluepairs"], "readwrite")
            .objectStore("keyvaluepairs");
          store.clear();
          window.location.reload();
        };
      }
    },
    REMOVE_TOKEN(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      state.jwt = null;
      state.auth = false;
      state.user = null;
    },
    UPDATE_FROM_LOCAL_STORAGE(state) {
      state.jwt = localStorage.getItem("token") || null;
      state.auth = !!state.jwt;
      state.user = JSON.parse(localStorage.getItem("user")) || null;
    }
  },
  actions: {
    obtainToken({ commit }, { token, account }) {
      ApiService.setAuthorizationHeader(token);
      commit("UPDATE_TOKEN", token);
      commit("UPDATE_USER", account);
      commit("UPDATE_AUTH", true);
    },
    async inspectToken({ state, commit }) {
      commit("UPDATE_FROM_LOCAL_STORAGE");
      const token = state.jwt;
      if (token) {
        await ApiService.post(state.endpoints.verifyJWT, { token: token })
          .then(res => res.data)
          .then(json => {
            if (json.token) {
              commit("UPDATE_USER", json.account);
              return null;
            }
            this.dispatch("logout");
          })
          .catch(e => {
            if (e.response.status > 500) {
              this.commit("UPDATE_UNDER_DEV", true);
              return;
            }
            this.dispatch("logout");
          });
        const decoded = jwt_decode(token);
        const exp = decoded.exp;
        // const orig_iat = decoded.orig_iat;
        // const seven_days = 604800; // 7*24*60*60
        const thirty_minutes = 30 * 60; // 30*60

        if (Date.now() / 1000 > exp) {
          commit("REMOVE_TOKEN");
          await router.push({ name: "SignIn" });
        } else if (Date.now() / 1000 > exp - thirty_minutes) {
          await this.dispatch("refreshToken");
        }

        // if (Date.now() / 1000 > exp) {
        //   // IF TOKEN EXPIRED THEN SEND TO LOGIN PAGE
        //   // router.push(“login”)
        // } else if (
        //   Date.now() / 1000 > exp - thirty_minutes &&
        //   Date.now() / 1000 < orig_iat + seven_days
        // ) {
        //   // IF TOKEN EXPIRE IN LESS THAN 30MN BUT STILL IN REFRESH PERIOD THEN REFRESH
        //   this.dispatch("refreshToken");
        // }
      }
    },
    async refreshToken({ commit, state }) {
      const payload = {
        token: state.jwt
      };
      await ApiService.post(state.endpoints.refreshJWT, payload)
        .then(response => {
          commit("UPDATE_TOKEN", response.data.token);
        })
        .catch(() => {
          commit("REMOVE_TOKEN");
        });
    },
    async signin({ state, dispatch, commit }, payload) {
      return API.signIn(payload)
        .then(response => response.data)
        .then(async result => {
          if ("token" in result) {
            await dispatch("obtainToken", result);
          }
          this._vm.$toast.success("Успешная авторизация");
          if (state.signInModal) {
            commit("UPDATE_SIGNIN_MODAL", false);
          } else {
            await router.push({ name: "Home" });
          }
        })
        .catch(error => {
          for (const val of Object.entries(error.response?.data || {})) {
            let strArray = [];
            let formattedVal = val[0];
            if (formattedVal !== "non_field_errors")
              strArray = [...strArray, val[0]];
            strArray = [...strArray, val[1].join(", ")];
            this._vm.$toast.error(strArray.join(": "));
          }
          throw error;
        });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit("REMOVE_TOKEN");
        ApiService.deleteAuthorizationHeader();
        localStorage.removeItem("invite");
        resolve();
        if (router.currentRoute.path !== "/") router.push({ name: "Home" });
        this._vm.$toast.success("Вы вышли из системы");
      });
    }
  },
  getters: {
    auth: s => !!s.auth,
    jwt: s => s.jwt,
    user: s => s.user,
    userIsAdmin: s => s.auth && s.user?.admin
  }
};
